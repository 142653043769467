import { memo } from "react";
import TagDetails from "./TagDetails";
import { Row } from "antd";

const TagAvatarConfig = (props) => {
  return (
    <>
      <Row gutter={[24, 24]}>
        {props.records.map((data) => (
          <TagDetails records={data} type="avatar" />
        ))}
      </Row>
    </>
  );
};

export default memo(TagAvatarConfig);
