import { memo, useState } from "react";
import { Input, AutoComplete, Form } from "antd";

const InputAutoComplete = (props) => {
  const [name, setName] = useState("");
  const [dataSource, setDataSource] = useState([]);

  const onNameChange = (value) => {
    // setName(value);

    const filteredNames = mockFetchNames(value);
    setDataSource(filteredNames);
  };

  //   const onSelect = (value) => {
  //     console.log("Selected:", value);
  //   };

  const mockFetchNames = (value) => {
    // Simulated function to fetch names based on the input value.
    // Replace this with an actual API call to retrieve names.
    const allNames = [
      "CloudShifu",
      "CostDeMaze",
      "Support Mate",
      "PMO Whisperer",
      "Employee Handbook",
    ];
    return allNames.filter((name) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
  };

  return (
    <>
      <Form.Item
        {...props.restField}
        name={[props.field.name, "fieldName"]}
        //   fieldKey={[field.fieldKey, "fieldName"]}
        className="mar-0"
      >
        <AutoComplete
          style={{ width: 200 }}
          options={dataSource.map((name) => ({
            value: name,
          }))}
          // onSelect={onSelect}
          onSearch={onNameChange}
          placeholder="Type here"
        >
          <Input
            //   value={name}
            //   onChange={(e) =>
            //     onNameChange(e.target.value)
            //   }
            //   onKeyDown={(e) => {
            //     e.preventDefault();
            //   }}
            className="edit-tag-member-input"
          />
        </AutoComplete>
      </Form.Item>
    </>
  );
};

export default memo(InputAutoComplete);
