import { memo } from "react";
import LoginBanner from "./LoginBanner";
import "../banners/SignInBanner.scss";
import "./login.scss";
import SignIn from "./SignIn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
import SignUp from "./SignUp";
import {
  loginEmailcheck,
  azureAdEmailCheck,
} from "../../components/api/accountAction";
import MobileSignin from "../Mobile/MobileSignin";
import MobileSignup from "../Mobile/MobileSignup";
import { useDispatch } from "react-redux";
import mobilebanner from "../../assets/mobile-banner.png";
import mobilelogo from "../../assets/mobilelogo.svg";
import { Button, Row, Col, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  cognitoSignIn,
  forgotPassword,
  submitForgotPassword,
} from "../Amplify/auth-config";
const Login = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const [signinstatus, setSigninstatus] = useState(false);
  const [signupstatus, setSignupstatus] = useState(false);
  const [buttonloader, setButtonLoader] = useState(false);
  const [azureerrorstatus, setAzureErrorStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openforgotpassword, setOpenForgotPassword] = useState(false);
  const [forgotemail, setForgotEmail] = useState("");
  const [formforgotstatus, setFormForgotStatus] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMobileSignin, setShowMobileSignin] = useState(false);
  const handleSignUpClick = (e) => {
    if (showSignIn) {
      setShowSignUp(true);
      setShowSignIn(false);
      localStorage.setItem("signUpShow", "signUp");
    } else {
      setShowSignUp(false);
      setShowSignIn(true);
      localStorage.setItem("signUpShow", "");
    }
  };
  const handleSignin = () => {
    // setSigninstatus(true)
    setButtonLoader(false);
    setShowMobileSignin(true);
  };

  const handleSignup = () => {
    navigate("./signup");
    // setSignupstatus(true)
  };
  const onFinishAzureAd = (values) => {
    setIsSubmitting(true);
    let email = values.email;

    dispatch(azureAdEmailCheck(email))
      .unwrap()
      .then(({ data }) => {
        setIsSubmitting(false);
        const records = JSON.parse(data.company_check);
        if (records === "company not exists") {
          setAzureErrorStatus(true);
        } else {
          const client_id = records.azureclient_id;
          const tenantid = records.tenant_id;
          const cognito_client_id = records.cognitoclient_id;
          window.location.href =
            "https://launcher.myapps.microsoft.com/api/signin/" +
            client_id +
            "?tenantId=" +
            tenantid;
        }
      })
      .catch((err) => {
        // console.log(err);
        setIsSubmitting(false);
        setAzureErrorStatus(true);
      });
  };
  const emailidVerification = () => {
    setButtonLoader(true);
    const input = {
      user_id: email,
      password: password,
    };
    // dispatch(cognitoSignIn({ email, password }))
    //   .unwrap()
    //   .then((signindata) => {
    //     console.log(signindata);
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //     message.error(err);
    //   });
    dispatch(loginEmailcheck(input))
      .unwrap()
      .then(({ data }) => {
        setButtonLoader(false);
        if (
          JSON.parse(data.aivolvex_general_prompts)[0] === "password incorrect"
        ) {
          message.error("Invalid Credentials");
        } else if (
          JSON.parse(data.aivolvex_general_prompts)[0] === "no such email_id"
        ) {
          message.error("Invalid Credentials");
        } else {
          console.log(JSON.parse(data.aivolvex_general_prompts)[0]);
          const encodedString = btoa(
            JSON.parse(data.aivolvex_general_prompts)[0].customer_name
          );
          const encodedEmail = btoa(email);
          const normalencodedString = btoa("normal");
          const tag_list = JSON.parse(data.aivolvex_general_prompts)[0].tag_id;
          var selected_tags = btoa("all");
          if (tag_list) {
            selected_tags = btoa(
              JSON.parse(data.aivolvex_general_prompts)[0].tag_id
            );
          }
          localStorage.setItem(
            "AIVolveXIdentityServiceProvider.com",
            encodedString
          );
          localStorage.setItem(
            "AIVolveXIdentityServiceProvider.logtype",
            normalencodedString
          );
          localStorage.setItem(
            "AIVolveXIdentityServiceProvider.auth",
            encodedEmail
          );
          localStorage.setItem(
            "AIVolveXIdentityServiceProvider.tags",
            selected_tags
          );
          window.location.href = "/avatarhub/enterprise";
          // navigateFn("/library");
        }
      })
      .catch((err) => {
        setButtonLoader(false);

        console.log(err.message);
      });
  };
  const onFinishForgotPasswordOTP = (val) => {
    const input = {
      emailid: val.email,
    };
    setForgotEmail(val.email);
    dispatch(forgotPassword(input))
      .unwrap()
      .then((data) => {
        setFormForgotStatus(false);
        message.success("Verification Code Sent Your Mail ID");
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const validatePassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The two passwords do not match!");
    },
  });

  const onFinishForgotPasswordSubmit = (val) => {
    const input = {
      emailid: forgotemail,
      otpvalue: val.verification_code,
      newpassword: val.password,
    };
    dispatch(submitForgotPassword(input))
      .unwrap()
      .then((data) => {
        message.success("Password changed successfully done");
        setOpenForgotPassword(false);
        setFormForgotStatus(true);
      })
      .catch((err) => {
        message.error(err);
      });
  };
  return (
    <>
      <div className="main-container d-flex h-screen">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 signin-banner singinbanner last-screen">
          <LoginBanner />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
          <div className="container signincontainer">
            <GoogleOAuthProvider
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
            >
              {showSignIn && (
                <SignIn
                  handleSignUpClick={handleSignUpClick}
                  emailidVerification={emailidVerification}
                  onFinishAzureAd={onFinishAzureAd}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                  azureerrorstatus={azureerrorstatus}
                  setAzureErrorStatus={setAzureErrorStatus}
                  onFinishForgotPasswordSubmit={onFinishForgotPasswordSubmit}
                  validatePassword={validatePassword}
                  onFinishForgotPasswordOTP={onFinishForgotPasswordOTP}
                  setOpenForgotPassword={setOpenForgotPassword}
                  openforgotpassword={openforgotpassword}
                  formforgotstatus={formforgotstatus}
                  setFormForgotStatus={setFormForgotStatus}
                />
              )}

              {/* {showSignUp && <SignUp handleSignUpClick={handleSignUpClick} />} */}
            </GoogleOAuthProvider>
          </div>
        </div>
      </div>
      {/* For Mobile responsive */}
      <div>
        {!showMobileSignin && (
          <div className="mobile-banner banner-bg">
            <Row>
              <Col className="mobile-col">
                <img src={mobilebanner} />
              </Col>
            </Row>

            <Row className="mobile-banner-logo tab-logo tab-head">
              <Col>
                <img src={mobilelogo} />
              </Col>
            </Row>
            <Row className="mobile-banner-logo-text">
              <Col>
                <p className="mobile-banner-text-normal">
                  where <span className="mobile-banner-text">brilliance</span>{" "}
                  meets
                </p>
              </Col>
            </Row>
            <Row className="mobile-banner-logo-text">
              <Col>
                <p className="mobile-banner-text-normal">
                  <span className="mobile-banner-text">boundless</span>{" "}
                  possibilties.
                </p>
              </Col>
            </Row>
            <Row className="mobile-banner-logo tab-logo">
              <Col>
                {" "}
                <Button
                  className="signin-btn"
                  onClick={handleSignin}
                  loading={buttonloader}
                >
                  SIGN IN
                </Button>
              </Col>
            </Row>
            <Row className="mobile-banner-logo">
              <Col>
                <Button className="signup-btn" onClick={handleSignup}>
                  REGISTER
                </Button>
              </Col>
            </Row>
          </div>
        )}

        {showMobileSignin && (
          <MobileSignin
            emailidVerification={emailidVerification}
            onFinishAzureAd={onFinishAzureAd}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            azureerrorstatus={azureerrorstatus}
            setAzureErrorStatus={setAzureErrorStatus}
            onFinishForgotPasswordSubmit={onFinishForgotPasswordSubmit}
            validatePassword={validatePassword}
            onFinishForgotPasswordOTP={onFinishForgotPasswordOTP}
            setOpenForgotPassword={setOpenForgotPassword}
            openforgotpassword={openforgotpassword}
            setShowMobileSignin={setShowMobileSignin}
            formforgotstatus={formforgotstatus}
            setFormForgotStatus={setFormForgotStatus}
          />
        )}
      </div>
    </>
  );
};

export default memo(Login);
