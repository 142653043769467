import { Auth } from "@aws-amplify/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const signUp = createAsyncThunk(
  // action type string
  "auth/signup",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const { user } = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          "custom:firstname": data.firstname,
          "custom:lastname": data.lastname,
          "custom:companyname": data.company_name,
        },
      });
      return user;
    } catch (error) {
      console.log(error);
      if (error.code === "UsernameExistsException") {
        return rejectWithValue(
          "An account with the given email already exists."
        );
      }
    }
  }
);

export const cognitoSignIn = createAsyncThunk(
  // action type string
  "auth/signin",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const user = await Auth.signIn(data.email, data.password);
      // console.log(user);
      return user;
    } catch (e) {
      if (e.code === "NotAuthorizedException") {
        return rejectWithValue("Incorrect username or password");
      } else if (e.code === "UserNotConfirmedException") {
        return rejectWithValue("User is not confirmed.");
      } else {
        return rejectWithValue("Incorrect username or password");
      }
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  // action type string
  "auth/verification",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.resendSignUp(data.email);
      // console.log("done");
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const VerificationEamil = createAsyncThunk(
  // action type string
  "auth/verification",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.confirmSignUp(data.email, data.email_otp);
      // console.log("done");
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  // action type string
  "auth/forgotpassword",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.forgotPassword(data.emailid);
    } catch (e) {
      if (e.code === "UserNotFoundException") {
        return rejectWithValue("Invalid Email Address");
      } else {
        return rejectWithValue("Invalid Email Address");
      }
    }
  }
);

export const submitForgotPassword = createAsyncThunk(
  // action type string
  "auth/forgotpassword",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.forgotPasswordSubmit(
        data.emailid,
        data.otpvalue,
        data.newpassword
      );
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);
