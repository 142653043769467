import { memo, useState } from "react";
import NavBar from "../Header/NavBar";
import Sidemenu from "../Sidemenu/Sidemenu";
import { Col, Row, Tabs } from "antd";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import TagMemberConfig from "./TagMemberConfig";
import "./rolehome.css";
import TagAvatarConfig from "./TagAvatarConfig";

const TagHome = () => {
  const [basicActive, setBasicActive] = useState("0");
  const [records, setRecords] = useState([
    {
      id: 1,
      title: "Sub Admin",
      members_list: [
        {
          members_id: 1,
          members_email: "vijayakumar@1cloudhub.com",
        },
        {
          members_id: 2,
          members_email: "rajeev@1cloudhub.com",
        },
        {
          members_id: 3,
          members_email: "deepika@1cloudhub.com",
        },
      ],
      avatar_list: [
        {
          avatar_id: 1,
          avatar: "CloudShifu",
        },
        {
          avatar_id: 2,
          avatar: "CostDeMaze",
        },
      ],
    },
    {
      id: 2,
      title: "Admin",
      members_list: [
        {
          members_id: 1,
          members_email: "vijayakumar1@1cloudhub.com",
        },
        {
          members_id: 2,
          members_email: "rajeev1@1cloudhub.com",
        },
        {
          members_id: 3,
          members_email: "deepika1@1cloudhub.com",
        },
      ],
      avatar_list: [
        {
          avatar_id: 1,
          avatar: "CloudShifu",
        },
        {
          avatar_id: 2,
          avatar: "CostDeMaze",
        },
        {
          avatar_id: 3,
          avatar: "Support Mate",
        },
      ],
    },
    {
      id: 3,
      title: "Admin",
      members_list: [
        {
          members_id: 1,
          members_email: "vijayakumar1@1cloudhub.com",
        },
        {
          members_id: 2,
          members_email: "rajeev1@1cloudhub.com",
        },
        {
          members_id: 3,
          members_email: "deepika1@1cloudhub.com",
        },
      ],
      avatar_list: [
        {
          avatar_id: 1,
          avatar: "CloudShifu",
        },
        {
          avatar_id: 2,
          avatar: "CostDeMaze",
        },
        {
          avatar_id: 3,
          avatar: "Support Mate",
        },
      ],
    },
  ]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  const items = [
    {
      key: 1,
      label: "Tags",
      children: <TagMemberConfig records={records} />,
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(1),
      active: basicActive === 1,
    },
    {
      key: 2,
      label: "Avatar",
      children: <TagAvatarConfig records={records} />,
      className: "plat-dashboard-link",
      onClick: () => handleBasicClick(2),
      active: basicActive === 2,
    },
  ];

  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={5}
            xl={5}
            className="window-margin-right  body-margin-top"
          >
            <Sidemenu />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={18}
            xl={18}
            className="body-margin-top"
          >
            <Row className="mt-1">
              <BreadCrumb
                homelink="/tag"
                breadcrumblist={[]}
                activebreadcroum={{
                  name: "Tags",
                  link: "/tag",
                }}
              />
              <Col span={24} className="d-flex justify-content-between">
                <h4 className="white-color">Tags</h4>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tabs
                  className="tag-tabs aivolvex-font"
                  items={items}
                  onChange={handleBasicClick}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(TagHome);
