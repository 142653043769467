import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "./client";
import {
  ONBOARD_AVATAR,
  LIST_AVATAR,
  UPDATE_AVATAR,
  GET_AVATAR_DETAILS,
  GET_START_CONVERSATION,
  LOGIN_EMAIL_CHECK,
  GET_START_CONVERSATION_CLOUDSHIFU,
  AZUREADEMAILCHECK,
  GET_START_CONVERSATION_KENDRA,
  AVATARFREQUENTLYPROMPTS,
  AVATARHISTORYTITLE,
  AVATARCHATHISTORY,
  CUSTOMERDETAILS,
  GETTOKENREMAINING,
  KENDRA_OPENAI,
  GET_START_CONVERSATION_PG_VECTOR,
  GET_START_CONVERSATION_GENERAL_CHAT,
  AVATAR_TAG_MEMBERS_LIST,
  SHARE_NEW_AVATRA,
  INSERT_NEW_FEEDBACK,
  FILEUPLOAD,
  HISTORY_EDIT,
  HISTORY_DELETE,
} from "./accountquery";

export const onboardAvatar = createAsyncThunk(
  // action type string
  "avatar/onboard",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log({ data });
    try {
      const onboardavatar = await client.mutate({
        mutation: ONBOARD_AVATAR,
        variables: {
          about: data.about,
          avatar_title: data.avatar_title,
          context: data.context,
          pod_category: data.pod_category,
          // prompt : data.prompt,
          avatar_image: data.image,
          version: data.version,
          avatar_owner: data.avatar_owner,
        },
      });
      return onboardavatar;
    } catch (error) {
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAvatar = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: LIST_AVATAR,
        variables: {
          company_name: data.company_name,
          tag_id: data.tag_id,
          // about: data.about,
          // avatar_title: data.avatar_title,
          // context: data.context,
          // pod_category: data.pod_category,
        },
      });
      return onboardavatar;
    } catch (error) {
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarDetails = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_AVATAR_DETAILS,
        variables: {
          id: data,
          // avatar_title: data.avatar_title,
          // context: data.context,
          // pod_category: data.pod_category,
        },
      });
      return onboardavatar;
    } catch (error) {
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversation = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION,
        variables: {
          date_time: data.date_time,
          user_session: data.user_session,
          avatar_id: data.avatar_id,
          chat: data.chat,
          ai: data.ai,
          schema: data.schema,
          user_name: data.user_name,
          managed_by: data.managed_by,
        },
      });
      return onboardavatar;
    } catch (error) {
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversationCloudShifu = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION_CLOUDSHIFU,
        variables: {
          date_time: data.date_time,
          user_session: data.user_session,
          chat: data.chat,
          schema: data.schema,
          user_name: data.user_name,
        },
      });
      return onboardavatar;
    } catch (error) {
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversationKendra = createAsyncThunk(
  // action type string
  "avatar/list",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION_KENDRA,
        variables: {
          chat: data.chat,
          session_id: data.user_session,
          avatar_id: data.avatar_id,
          customer: data.schema,
          user_id: data.user_name,
        },
      });
      return onboardavatar;
    } catch (error) {
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversationPgVector = createAsyncThunk(
  // action type string
  "avatar/chat/pgvector",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION_PG_VECTOR,
        variables: {
          chat: data.chat,
          session_id: data.user_session,
          avatar_id: data.avatar_id,
          customer: data.schema,
          user_id: data.user_name,
        },
      });
      return onboardavatar;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startConversationGeneralChat = createAsyncThunk(
  // action type string
  "avatar/chat/generalchat",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const onboardavatar = await client.mutate({
        mutation: GET_START_CONVERSATION_GENERAL_CHAT,
        variables: {
          chat: data.chat,
          session_id: data.user_session,
          avatar_id: data.avatar_id,
          customer: data.schema,
          user_id: data.user_name,
          date_time: data.date_time,
          managed_by: data.managed_by,
        },
      });
      return onboardavatar;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAvatar = createAsyncThunk(
  // action type string
  "avatar/onboard",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log({ data });
    try {
      const updateAvatar = await client.mutate({
        mutation: UPDATE_AVATAR,
        variables: {
          about_us: data.about,
          avatar_id: data.id,
          avatar_name: data.avatar_title,
          context: data.context,
          pod: data.pod_category,
          // prompt : data.prompt,
          avatar_image: data.image,
          version: data.version,
          avatar_owner: data.avatar_owner,
        },
      });
      return updateAvatar;
    } catch (error) {
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const loginEmailcheck = createAsyncThunk(
  // action type string
  "email/login",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const updateAvatar = await client.mutate({
        mutation: LOGIN_EMAIL_CHECK,
        variables: {
          user_id: data.user_id,
          password: data.password,
        },
      });
      return updateAvatar;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const azureAdEmailCheck = createAsyncThunk(
  // action type string
  "email/login",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const azuread = await client.mutate({
        mutation: AZUREADEMAILCHECK,
        variables: {
          email: data,
        },
      });
      return azuread;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarFrequentlyPrompts = createAsyncThunk(
  // action type string
  "prompts/frequently",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const frequently = await client.mutate({
        mutation: AVATARFREQUENTLYPROMPTS,
        variables: {
          id: data.id,
          user_id: data.user_id,
        },
      });
      return frequently;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarHistoryTitle = createAsyncThunk(
  // action type string
  "prompts/historytitle",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const history_title = await client.mutate({
        mutation: AVATARHISTORYTITLE,
        variables: {
          id: data.id,
          user_id: data.user_id,
        },
      });
      return history_title;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarChatHistory = createAsyncThunk(
  // action type string
  "prompts/chathistory",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: AVATARCHATHISTORY,
        variables: {
          conversationid: data.conversationid,
        },
      });
      return chat_history;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomerDetails = createAsyncThunk(
  // action type string
  "prompts/chathistory",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: CUSTOMERDETAILS,
        variables: {
          email: data,
        },
      });
      return chat_history;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTokenRemaining = createAsyncThunk(
  // action type string
  "prompts/chathistory",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: GETTOKENREMAINING,
        variables: {
          company: data.company,
        },
      });
      return chat_history;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getKendraOpenAI = createAsyncThunk(
  // action type string
  "prompts/kendraopenai",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const chat_history = await client.mutate({
        mutation: KENDRA_OPENAI,
        variables: {
          prompts: data.prompts,
          session_id: data.user_session,
          avatar_id: data.avatar_id,
          customer: data.schema,
          user_id: data.user_name,
          question: data.question,
        },
      });
      return chat_history;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAvatarTagandMembersList = createAsyncThunk(
  // action type string
  "avatar/tagmemberslist",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const avatar_option_list = await client.mutate({
        mutation: AVATAR_TAG_MEMBERS_LIST,
        variables: {
          event_type: data.event_type,
          company: data.company,
          share_type: data.share_type,
          user_id: data.user_id,
          avatar_id: data.avatar_id,
        },
      });
      return avatar_option_list;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const shareAvatarConversion = createAsyncThunk(
  // action type string
  "avatar/share",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const avatar_share = await client.mutate({
        mutation: SHARE_NEW_AVATRA,
        variables: {
          share_type: data.share_type,
          user_id: data.user_id,
          user_session: data.user_session,
          selected_id: data.selected_id,
          title: data.title,
          company: data.company,
          avatar_id: data.avatar_id,
        },
      });
      return avatar_share;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const fileUpload = createAsyncThunk(
  // action type string
  "avatar/share",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const avatar_file = await client.mutate({
        mutation: FILEUPLOAD,
        variables: {
          // event_type:data.event_type
        },
      });
      return avatar_file;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const nsertNewFeedback = createAsyncThunk(
  // action type string
  "prompts/kendraopenai",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const chat_history = await client.mutate({
        mutation: INSERT_NEW_FEEDBACK,
        variables: {
          rating: data.rating,
          feedback: data.feedback,
          avatar_id: data.avatar_id,
          user_id: data.user_id,
          question_id: data.question_id,
          company: data.company,
        },
      });
      return chat_history;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const updatehistory = createAsyncThunk(
//   // action type string
//   "prompts/kendraopenai",
//   // callback function
//   async (data, { rejectWithValue }) => {
//     try {
//       const chat_history_edit = await client.mutate({
//         mutation: HISTORY_EDIT,
//         variables: {
//           rating: data.rating,
//           feedback: data.feedback,
//           avatar_id: data.avatar_id,
//           user_id: data.user_id,
//           question_id: data.question_id,
//           company: data.company,
//         },
//       });
//       return chat_history_edit;
//     } catch (error) {
//       if (error.response && error.response.data.message) {
//         return rejectWithValue(error.response.data.message);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );
// export const deletehistory = createAsyncThunk(
//   // action type string
//   "prompts/kendraopenai",
//   // callback function
//   async (data, { rejectWithValue }) => {
//     try {
//       const chat_history_delete = await client.mutate({
//         mutation:HISTORY_DELETE,
//         variables: {
//           rating: data.rating,
//           feedback: data.feedback,
//           avatar_id: data.avatar_id,
//           user_id: data.user_id,
//           question_id: data.question_id,
//           company: data.company,
//         },
//       });
//       return chat_history_delete;
//     } catch (error) {
//       if (error.response && error.response.data.message) {
//         return rejectWithValue(error.response.data.message);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   }
// );