import { memo, useState } from "react";
import { Button, Form, Input, Row, Col } from "antd";
import { Icon } from "@iconify/react";
import TagDetails from "./TagDetails";

const TagMemberConfig = (props) => {
  const [form] = Form.useForm();
  const [addstatus, setAddStatus] = useState(true);
  const [entertitle, setEnterTitle] = useState("");
  const onNewFinish = (values) => {
    console.log(entertitle);
    console.log(values);
    setAddStatus(true);
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        {props.records.map((data) => (
          <TagDetails records={data} type="member" />
        ))}
        {addstatus ? (
          <Col xxl={6} xl={6} lg={6} md={8} sm={24} xs={24}>
            <div
              className="tag-card aivolvex-font tag-card-icon-center cursor-pointer"
              onClick={() => setAddStatus(false)}
            >
              <Icon icon="fluent:add-32-filled" className="card-tag-add-icon" />
            </div>
          </Col>
        ) : (
          <Col xxl={6} xl={6} lg={6} md={8} sm={24} xs={24}>
            <Form
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 18,
              }}
              form={form}
              name="dynamic_form_complex_new"
              style={{
                maxWidth: 600,
              }}
              autoComplete="off"
              onFinish={onNewFinish}
              initialValues={{}}
            >
              <Form.List name="additems">
                {(newfields, { add, remove }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="tag-card aivolvex-font">
                      <div className="tag-card-head d-flex justify-content-between">
                        <div>
                          <h3 className="tag-card-head-title">
                            <Input
                              placeholder="Tag Title"
                              className="edit-tag-add-input"
                              bordered={false}
                              onChange={(e) => {
                                setEnterTitle(e.target.value);
                              }}
                            />
                          </h3>
                        </div>
                        <div>
                          <Icon
                            icon="iconamoon:close"
                            className="tag-card-head-icon cursor-pointer"
                            onClick={() => setAddStatus(true)}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="tag-card-member-list">
                          <ul className="tag-card-list tag-card-list-scroll">
                            {newfields.map((field, index, { ...restField }) => (
                              <>
                                <li className="edit-tag-list" key={field.key}>
                                  <div>
                                    <Form.Item
                                      {...restField}
                                      name={[field.name, "fieldName"]}
                                      fieldKey={[field.fieldKey, "fieldName"]}
                                      className="mar-0"
                                    >
                                      <Input
                                        placeholder="Type here"
                                        className="edit-tag-member-input"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div>
                                    <span className="edit-tag-close-icon">
                                      <Icon
                                        icon="iconamoon:close"
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      />
                                    </span>
                                  </div>
                                </li>
                              </>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tag-classic-bg d-flex justify-content-center">
                      <div>
                        <label
                          className="add-tag-label"
                          type="dashed"
                          onClick={() => add()}
                          block
                        >
                          Add Member
                        </label>
                      </div>
                    </div>
                    <div className="tag-classic-bg tag-center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="add-tag-btn"
                      >
                        Add New Tag
                      </Button>
                    </div>
                  </div>
                )}
              </Form.List>
            </Form>
          </Col>
        )}
      </Row>
    </>
  );
};

export default memo(TagMemberConfig);
